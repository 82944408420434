import { getCountries } from 'actions/country';
import { setPermissions } from 'actions/permissions';
import { getCart } from 'actions/product';
import { getSettings } from 'actions/setting';
import { closeConfirmedMessage, setIsConfirmed } from 'actions/ui';
import request from 'tools/request';
import {
  getAdminToken,
  handleWixConnection,
  removeAdminToken,
  removeToken,
  setToken,
} from 'tools/utils';
import router from 'next/router';

import { LOGIN_ERRORS } from '../../tools/constants';
import message from '../../components/utils/message';
import { ShopifyTokenProvider } from '../../tools/shopifyTokenProvider';
import { useIsEmbedded } from '../../hooks/useIsEmbedded';
import { getAddresses, setAddresses } from 'actions/address';
import { getState } from 'store/index';
import { createCatalog } from 'actions/channel';
import TagManager from 'react-gtm-module';

function user(payload) {
  return { type: 'USER', payload };
}

export function setCountryInfo(payload) {
  return { type: 'SET_COUNTRY_INFO', payload };
}

export function setRoles(payload) {
  return { type: 'SET_ROLES', payload };
}
export function setStatus(payload) {
  return { type: 'SET_STATUS', payload };
}

export function setEcredit(payload) {
  return { type: 'SET_ECREDIT', payload };
}

function userIsLoading(payload) {
  return { type: 'USER_IS_LOADING', payload };
}

function isLoggedIn(payload) {
  return { type: 'IS_LOGGED_IN', payload };
}

export function showNewSignupPopupAction(payload) {
  return { type: 'SHOW_NEW_SIGNUP_POPUP', payload };
}

function userLogout() {
  return { type: 'USER_LOGOUT' };
}

export function attachUserCatalog(onComplete = () => {}) {
  return (dispatch, getState) => {
    let userCatalogs = [];
    request('/api/user_catalog', {
      params: {
        count: true,
      },
    })
      .then(({ data }) => (userCatalogs = data))
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      })
      .finally(() => {
        const _user = getState()['user'];
        if (
          _user?.roles?.[0]?.name === 'free' &&
          userCatalogs?.length === 0 &&
          _user.channelCode !== 'wix'
        ) {
          const currency = 'EUR';
          dispatch(
            createCatalog({
              ids: [],
              connections: [
                {
                  service: 'socialEcommerce',
                  component: 'socialEcommerce',
                },
              ],
              name: 'Wishlist',
              language: _user?.lang,
              currency,
              minQuantity: 1,
              allProducts: true,
            })
          );
        } else {
          dispatch(user({ ...getState()['user'], userCatalogs }));
          onComplete(userCatalogs);
        }
      });
  };
}
export function getUserCatalog(catalogId, onComplete) {
  return () => {
    request(`/api/user_catalog/${catalogId}`, {})
      .then((response) => onComplete(response.data))
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      });
  };
}

export function logon(data, saveToken = true, onSuccess = () => {}) {
  return (dispatch) => {
    const { UI } = getState();
    const adminToken = getAdminToken();
    dispatch(
      user({
        ...data,
        userCatalogs: [],
        adminToken: adminToken?.length ? adminToken : undefined,
      })
    );
    if (data?.status === 1) {
      dispatch(closeConfirmedMessage());
    }
    if (data._id) {
      saveToken && setToken(data.token);
      dispatch(setPermissions(data.roles));
      dispatch(getCountries());
      dispatch(getSettings());
      dispatch(getCart());
      dispatch(
        getAddresses((data) => {
          dispatch(setAddresses(data));
        })
      );
      dispatch(
        attachUserCatalog((userCatalogs) => {
          onSuccess(userCatalogs);
        })
      );
      dispatch(isLoggedIn(true));
    } else {
      onSuccess();
      dispatch(isLoggedIn(false));
    }
  };
}

export function getUser(
  saveToken = false,
  onError = () => {},
  onComplete = () => {}
) {
  return (dispatch) => {
    dispatch(userIsLoading(true));
    dispatch(getSettings());
    request('/api/user/me')
      .then((response) => {
        dispatch(
          logon(response.data, saveToken, (userCatalogs) => {
            console.log('onComplete logon');
            onComplete(userCatalogs);
          })
        );
      })
      .catch((err) => {
        onError(err);
      })
      .finally(() => {
        dispatch(userIsLoading(false));
      });
  };
}

export function getUserRole() {
  return async (dispatch, getState) => {
    await request('/api/user/me').then((response) => {
      dispatch(user({ ...getState()['user'], roles: response.data.roles }));
      dispatch(setPermissions(response.data.roles));
    });
  };
}

export function login(data, onSuccess = () => {}, onError = () => {}, service) {
  return (dispatch) => {
    console.log('service', service);
    const isEmbedded = useIsEmbedded();
    request('/api/auth/login', {
      method: 'POST',
      data,
    })
      .then((response) => {
        setToken(response.data.token);
        if (isEmbedded) {
          let shopifyTokenProvider = ShopifyTokenProvider.getInstance();
          shopifyTokenProvider.setToken(response.data.token);
        }
        dispatch(
          getUser(
            false,
            (err) => {
              if (err?.data && err?.data?.status === 403) {
                if (err?.data?.code === 'email_not_confirmed') {
                  dispatch(setIsConfirmed(data.email));
                } else if (err?.data?.code === 'role_expired') {
                  onError(LOGIN_ERRORS.ROLE_EXPIRED);
                }
              }
            },
            async (userCatalogs) => {
              console.log('onComplete getUser');
              if (service === 'wix') {
                await handleWixConnection();
              }
              onSuccess(userCatalogs);
            }
          )
        );
      })
      .catch((err) => {
        if (err.status === 412) {
          onError(LOGIN_ERRORS.FORCE_TO_RESET_PASSWORD);
        } else {
          onError(LOGIN_ERRORS.CREDENTIALS);
        }
      });
  };
}

export function loginSocial(
  data,
  onSuccess = () => {},
  onError = () => {},
  isRegistration = false
) {
  return (dispatch) => {
    request('/api/auth/login/social', {
      method: 'POST',
      data,
    })
      .then((response) => {
        if (!response?.data?.missingFields) {
          setToken(response.data.token);
          if (isRegistration) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'registration',
                authenticationMethod: 'Social',
                userEmail: response?.data?.email?.toLowerCase(),
                userCountry: data?.countryCode?.toLowerCase(),
              },
            });
          } else {
            TagManager.dataLayer({
              dataLayer: {
                event: 'login',
                method: 'Social',
                userEmail: response?.data?.email?.toLowerCase(),
              },
            });
          }
          dispatch(
            getUser(
              false,
              (err) => {
                if (err?.data && err?.data?.status === 403) {
                  if (err?.data?.code === 'email_not_confirmed') {
                    dispatch(setIsConfirmed(data.email));
                  } else if (err?.data?.code === 'role_expired') {
                    onError(LOGIN_ERRORS.ROLE_EXPIRED);
                  }
                } else if (err?.data && err?.data?.status === 400) {
                  if (err?.data?.code === 'no_email_from_social') {
                    onError(LOGIN_ERRORS.NO_EMAIL_FROM_SOCIAL);
                  }
                }
              },
              async (userCatalogs) => {
                onSuccess(userCatalogs);
              }
            )
          );
        } else {
          onSuccess(response);
        }
      })
      .catch((err) => {
        onError(LOGIN_ERRORS.CREDENTIALS);
      });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(userLogout());
    removeToken();
    removeAdminToken();
    dispatch(logon({}, false));
    dispatch(userIsLoading(false));
    router.push('/');
  };
}

export function updateCatalogProducts({ catalogId, ids, onComplete }) {
  return (dispatch) => {
    request(`/api/user_catalog/${catalogId}`, {
      method: 'PUT',
      data: {
        ids,
      },
    })
      .then(() => {
        dispatch(attachUserCatalog());
      })
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      })
      .finally(onComplete);
  };
}

export function updateCatalog(catalogId, catalog, onSuccess = () => {}) {
  return (dispatch) => {
    request(`/api/user_catalog/${catalogId}`, {
      method: 'PUT',
      data: {
        ...catalog,
      },
    })
      .then(() => {
        dispatch(attachUserCatalog());
        onSuccess();
      })
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      })
      .finally(() => {});
  };
}

export function signup(
  {
    email,
    name,
    password,
    phone,
    countryId,
    currency,
    lang,
    limitTarsCount,
    localeCode,
    privacy1,
    privacy2,
    newsletters,
    countryCode,
    DidYouKnowBDroppy,
    alreadySell,
    website,
    description,
    avgTurnover,
    assistanceRequested,
    gclid,
  } = user,
  onComplete = () => {}
) {
  return (dispatch) => {
    request('/api/auth/signup', {
      method: 'POST',
      data: {
        name,
        email,
        password,
        phone,
        countryId,
        currency,
        lang,
        limitTarsCount,
        localeCode,
        privacy1,
        privacy2,
        newsletters,
        countryCode,
        DidYouKnowBDroppy,
        alreadySell,
        website,
        description,
        avgTurnover,
        assistanceRequested,
        gclid,
      },
    })
      .then(onComplete)
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      });
  };
}

export function preregister(
  {
    email,
    name,
    phone,
    countryId,
    currency,
    lang,
    limitTarsCount,
    localeCode,
    privacy1,
    privacy2,
    newsletters,
    countryCode,
    DidYouKnowBDroppy,
    website,
    description,
    avgTurnover,
    assistanceRequested,
    useLoader,
    gclid,
  },
  onComplete = () => {}
) {
  return async (dispatch) => {
    await request('/api/auth/preregister', {
      method: 'POST',
      data: {
        email,
        name,
        phone,
        countryId,
        currency,
        lang,
        limitTarsCount,
        localeCode,
        privacy1,
        privacy2,
        newsletters,
        countryCode,
        DidYouKnowBDroppy,
        website,
        description,
        avgTurnover,
        assistanceRequested,
        gclid,
      },
      useLoader,
    })
      .then((response) => onComplete(response))
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      });
  };
}
export function verifyEmail(email) {
  return async (dispatch) => await request(`/api/user/email/${email}/check`);
}

export function resendEmail(email) {
  return async (dispatch) =>
    await request(`/api/user/email/${email}/send/activation`);
}

export function changePassword(data) {
  return async (dispatch) =>
    await request('/api/auth/reset_password', {
      method: 'POST',
      data,
    });
}

export function createPassword({ uuid, password }) {
  return async (dispatch) =>
    await request(`/api/auth/create_password/${uuid}`, {
      method: 'POST',
      data: {
        password,
      },
    });
}

export function getUserPermissions(onComplete = () => {}, onError = () => {}) {
  return (dispatch) => {
    request('/api/user/me')
      .then((response) => {
        dispatch(setPermissions(response?.data?.roles || []));
        dispatch(setRoles(response?.data?.roles || []));
      })
      .catch((err) => {
        onError(err);
      })
      .finally(() => {
        onComplete();
      });
  };
}

export function getUserStatus(onComplete = () => {}, onError = () => {}) {
  return (dispatch) => {
    let status = null;
    request('/api/user/me')
      .then((response) => {
        dispatch(setStatus(response?.data?.status || 1));
        status = response?.data?.status || 1;
      })
      .catch((err) => {
        onError(err);
      })
      .finally(() => {
        onComplete(status);
      });
  };
}

export function getUserEcredit(onComplete = () => {}, onError = () => {}) {
  return (dispatch) => {
    request('/api/user/me')
      .then((response) => {
        dispatch(setEcredit(response?.data?.ecredit || 0));
      })
      .catch((err) => {
        onError(err);
      })
      .finally(() => {
        onComplete();
      });
  };
}

export function getCountryInfoApi() {
  return async () => await request('/api/ipinfo/get');
}

export function getImagesApi() {
  return async () => await request('/api/img/brands/all');
}

export function getTransactions(params, onSuccess = () => {}) {
  return () => {
    request('/api/ecredit_transaction/filter', {
      params,
      method: 'GET',
    })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      });
  };
}

export function getReferralInfo(onSuccess = () => {}) {
  return () => {
    request('/api/user/me/referrals', {
      method: 'GET',
    })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      });
  };
}

export function updateUser(data, onSuccess = () => {}) {
  return () => {
    request(`/api/user/me`, {
      method: 'PUT',
      data: data,
    })
      .then(onSuccess)
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      });
  };
}

export function getActiveSubscription(
  onSuccess = () => {},
  onError = () => {}
) {
  return () => {
    request(`/api/subscription`, {
      params: {
        active: true,
      },
    })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch(onError);
  };
}

export function unsubscribe(
  subscriptionId,
  onSuccess = () => {},
  onError = () => {}
) {
  return (dispatch) => {
    request(`/api/subscription/${subscriptionId}/unsubscribe`)
      .then((response) => {
        onSuccess(response.data);
      })
      .catch(onError)
      .finally(() => {});
  };
}

export function getStatsChannel(catalogId, onComplete) {
  return () => {
    request(`/api/user_catalog/${catalogId}/stats`, {})
      .then((response) => onComplete(response.data))
      .catch((err) => {
        message.error(err?.data?.message || 'Server error');
      });
  };
}

export function cleanImport(catalogId) {
  return async (dispatch) =>
    await request(`/api/user_catalog/${catalogId}/clean_import`, {
      method: 'PUT',
    });
}

export function withdrawTransaction(data) {
  return async (dispatch) =>
    await request('/api/withdraw_transaction', {
      method: 'POST',
      data,
    });
}

export function refetchUser() {
  return async (dispatch) => {
    let response = await request('/api/user/me');
    dispatch(user(response.data));
  };
}
