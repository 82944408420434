import message from 'components/utils/message';
import { LOCALES } from 'tools/constants';
import request from 'tools/request';
import { getState } from 'store/index';
import { cartProducts, setAnonymousUserId } from 'actions/ui';
import { generateObjectId, getAdminToken } from '../../tools/utils';

export function searchedProductsBySKU(payload) {
  return { type: 'SEARCHED_PRODUCTS_BY_SKU', payload };
}

export function setProducts(payload) {
  return { type: 'SET_PRODUCTS', payload };
}

function setProduct(payload) {
  return { type: 'SET_PRODUCT', payload };
}

function setBrands(payload) {
  return { type: 'SET_BRANDS', payload };
}

function setCategories(payload) {
  return { type: 'SET_CATEGORIES', payload };
}

function setSubCategories(payload) {
  return { type: 'SET_SUBCATEGORIES', payload };
}

function setSizes(payload) {
  return { type: 'SET_SIZES', payload };
}

function setGenders(payload) {
  return { type: 'SET_GENDERS', payload };
}

function setSeasons(payload) {
  return { type: 'SET_SEASONS', payload };
}

function setCanali(payload) {
  return { type: 'SET_CANALI', payload };
}

export function setVouchers(payload) {
  return { type: 'SET_VOUCHERS', payload };
}

export function searchProductsBySKU(value, setLoading = () => {}) {
  return (dispatch) => {
    if (!value) {
      dispatch(searchedProductsBySKU([]));
    } else {
      setLoading(true);
      request(`/api/product/autocomplete/${value}`)
        .then((response) => dispatch(searchedProductsBySKU(response.data)))
        .catch((err) => message.error("Can't load products"))
        .finally(() => setLoading(false));
    }
  };
}

export function getProducts(
  params = {},
  onComplete = () => {},
  useLoader = true
) {
  return (dispatch) => {
    let _data = [];
    const locales = LOCALES.join(',') + ',';
    if (params.brand) {
      params.brand = encodeURIComponent(params.brand);
    }
    if (params?.gender === 'men' || params.gender === 'women') {
      params.gender = `${params.gender},unisex`;
    }

    request(`/api/product/export`, {
      params: {
        acceptedlocales: locales,
        ...params,
      },
      useLoader,
    })
      .then((response) => {
        _data = response.data;
      })
      .catch((err) => {
        message.error("Can't load products");
        _data = {
          items: [],
          totalPages: 0,
          currentPage: 1,
        };
      })
      .finally(() => {
        onComplete(_data);
      });
  };
}

export function getCarriers(onComplete = () => {}, useLoader = true) {
  return (dispatch) => {
    let data;

    request(`/api/carrier_fee/filter`, {
      params: { pageSize: 10000, p: ['country'] },
      useLoader,
    })
      .then((response) => {
        data = response?.data?.items;
      })
      .catch((err) => message.error("Can't load carriers"))
      .finally(() => {
        onComplete(data);
      });
  };
}

export function getAllProductsIds(params = {}, onComplete = () => {}) {
  return () => {
    const locales = LOCALES.join(',') + ',';
    request(`/api/product/export`, {
      params: {
        onlyid: true,
        sort: 'lastUpdate-desc',
        ...params,
      },
    })
      .then((response) => {
        onComplete(response.data.items);
      })
      .catch((err) => message.error("Can't load products"));
  };
}

export function getBrands(params = {}, useLoader = false) {
  return (dispatch) => {
    request('/api/brand', { params: { ...params }, useLoader })
      .then((response) => dispatch(setBrands(response.data)))
      .catch((err) => console.log(err));
  };
}

export function getCategories(params = {}, useLoader = true) {
  return (dispatch) => {
    request('/api/category', { params, useLoader })
      .then((response) => dispatch(setCategories(response.data)))
      .catch((err) => console.log(err));
  };
}

export function getTags(params = {}, useLoader = false) {
  return (dispatch) => {
    request('/api/tag', { params, useLoader })
      .then((response) => {
        dispatch(setSubCategories(response.data.subcategory || []));
        dispatch(setCategories(response.data.category || []));
        dispatch(setBrands(response.data.brand || []));
        dispatch(setSizes(response.data.size || []));
        dispatch(setGenders(response.data.gender || []));
        dispatch(setSeasons(response.data.season || []));
        dispatch(setCanali(response.data.canale || []));
      })
      .catch((err) => console.log(err));
  };
}

export function getCart(params = {}) {
  return async (dispatch) => {
    const tempProducts = getState().cartProducts;
    const adminToken = getAdminToken();
    if (tempProducts.length && !adminToken) {
      await dispatch(
        addProductReservationCart(
          tempProducts.map((product) => ({
            model: product?.model?.id,
            quantity: product?.quantity,
          }))
        )
      );
    }
    request('/api/product_reservation/cart', { params })
      .then((response) => {
        const products = response?.data;
        products.forEach((product) => {
          product.model =
            product?.models[
              product.models.findIndex(
                (model) =>
                  model?.id?.toString() === product?.modelId?.toString()
              )
            ];
        });
        dispatch(cartProducts(products));
      })
      .catch((err) => console.log(err));
  };
}

export function getSubCategories(params = {}, useLoader = true) {
  return (dispatch) => {
    request('/api/subcategory', { params, useLoader })
      .then((response) => dispatch(setSubCategories(response.data)))
      .catch((err) => console.log(err));
  };
}

export function getProduct(id, setLoading = () => {}) {
  return (dispatch) => {
    setLoading(true);
    request(`/api/product/${id}`)
      .then((response) => dispatch(setProduct(response.data)))
      .catch((err) => message.error("Can't load product"))
      .finally(() => setLoading(false));
  };
}

export function getProductOverwrite(id, productId, onComplete = () => {}) {
  return () => {
    request(`/api/product/${productId}`)
      .then((response) => {
        const product = {
          title:
            response?.data.title ||
            `${response?.data.brand} - ${response?.data.name}`,
          description: response?.data.descriptions,
          pictures: response?.data.pictures,
          productId: null,
          tags: response?.data.tags,
          product: response.data._id,
        };
        request(
          `/api/product_overwrite/product/${productId}/catalog/${id}`
        ).then((response) => {
          const overwriteProduct = response?.data;
          if (overwriteProduct) {
            product.title = overwriteProduct?.title;
            product.description = overwriteProduct?.description;
            product._id = overwriteProduct?._id;
          }
          onComplete(product);
        });
      })
      .catch((err) => message.error("Can't load product"));
  };
}

export function createProductOverwrite(data, onSuccess = () => {}) {
  return (dispatch) => {
    request(`/api/product_overwrite`, { method: 'POST', data })
      .then((response) => onSuccess(response.data))
      .catch((err) => message.error("Can't load product"));
  };
}

export function updateProductOverwrite(productId, data, setLoading = () => {}) {
  return (dispatch) => {
    setLoading(true);
    request(`/api/product_overwrite/${productId}`, { method: 'PUT', data })
      .then((response) => console.log(response.data))
      .catch((err) => message.error("Can't load product"))
      .finally(() => setLoading(false));
  };
}

export function getLongToken() {
  return async () => await request(`/api/auth/token/long`);
}

export function getFile(
  userCatalogId,
  locale,
  code,
  saveFormat = 'csv',
  getLink = false,
  token,
  onComplete = () => {}
) {
  return () => {
    if (getLink) {
      if (token) {
        return onComplete(
          `/api/export/api/products${code}?acceptedlocales=${locale}&user_catalog=${userCatalogId}&token=${token}`
        );
      }
    }
    request(
      `/api/export/api/products${code}?acceptedlocales=${locale}&user_catalog=${userCatalogId}`,
      {
        headers: {
          'Content-Type': 'application/octet-stream; charset=utf-8',
          Accept: 'application/octet-stream;  charset=utf-8',
        },
        responseType: 'blob',
      }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.' + saveFormat);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        message.error("Can't download file");
      })
      .finally(() => onComplete());
  };
}

export function updatePlans(productId, { models }) {
  return (dispatch) => {
    request(`/api/product/${productId}`, { method: 'PUT', data: { models } })
      .then((response) => console.log(response.data))
      .catch((err) => message.error("Can't load product"));
  };
}

export function getSupportsGold(
  params,
  onSuccess = () => {},
  onError = () => {}
) {
  return (dispatch) => {
    request(`/api/stripe_gold/filter`, { method: 'GET', params })
      .then((response) => onSuccess(response.data))
      .catch(onError);
  };
}

export function updateSupportsGold(
  _id,
  data,
  onSuccess = () => {},
  onError = () => {}
) {
  return (dispatch) => {
    request(`/api/stripe_gold/${_id}`, { method: 'PUT', data })
      .then((response) => onSuccess(response))
      .catch(onError);
  };
}

export function getArticleStatusApi(
  data,
  onSuccess = () => {},
  onError = () => {}
) {
  return (dispatch) => {
    request(
      `/api/tars/supplier_article/status/datasource/${data.datasourceId}/channelexport/${data.channelExportId}/skus?sku=${data.skus}`
    )
      .then((response) => onSuccess(response.data))
      .catch(onError);
  };
}

export function addToFavourites(data) {
  return async () =>
    await request('/api/product_social/favorite', {
      method: 'PUT',
      data,
      useLoader: false,
    });
}

export function addProductReservationCart(data) {
  return async (dispatch) => {
    const { isLoggedIn, anonymousUserId } = getState();
    try {
      const body = {
        method: 'POST',
        data,
      };
      if (!isLoggedIn) {
        if (anonymousUserId) {
          body.headers = {
            'x-temporary-user-id': anonymousUserId,
          };
        } else {
          const objectId = generateObjectId();
          dispatch(setAnonymousUserId(objectId.toString()));
          body.headers = {
            'x-temporary-user-id': objectId.toString(),
          };
        }
      }
      return await request('/api/product_reservation/cart', {
        ...body,
      });
    } catch (err) {
      if (err.status !== 401) {
        throw err;
      }
    }
  };
}

export function getSizes(params) {
  return async () => {
    const response = await request(`/api/size`, {
      method: 'GET',
      params,
    });
    return response.data;
  };
}

export function verifyVoucher(data) {
  return async () =>
    await request(`/api/voucher/verify?voucherCodes=${data}`, {
      method: 'GET',
    });
}

export function getCMSDetail(code = '') {
  return async () =>
    await request(`/api/setting/filter`, {
      method: 'GET',
      params: {
        q: {
          type: { $eq: 'ProductsCMS' },
        },
        p: {
          [`body.products.${code}`]: 1,
        },
      },
    });
}
